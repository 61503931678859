import React from "react";
import Slider from "react-slick";
import SliderItem from "../../../../components/slider/slideritem";
import carouselImageOne from "../../../../images/slider-banner-1.jpg";
import carouselImageTwo from "../../../../images/imgslider2.jpg";
import { useHomeBlog } from "../../../../context/HomeBlogContext";
import Loading from "../../../../components/shared/Loading/Loading";

const CarouselSection = ({ carouselItems, setCarouselItems }) => {
  const {
    lang,
    addSection,
    isAddSectionLoading,
    updateSection,
    isUpdateSectionLoading,
  } = useHomeBlog();
  var settings = {
    dots: true,
    infinite: carouselItems?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };
  const urlToFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.split("/").pop();
    return new File([blob], filename, { type: blob.type });
  };
  const updateCarousel = () => {
    updateSection({
      blogLanguageId: lang.id,
      index: 1,
      content: carouselItems.map((item) => item.content).join("-.-"),
      _method: "PUT",
    });
  };
  const changeCarouselImage = async (i, img) => {
    const formData = new FormData();
    formData.append("blogLanguageId", lang.id);
    formData.append("index", 1);
    formData.append("_method", "PUT");
    formData.append(`images[${i}]`, img);
    await updateSection(formData);
  };
  const addCarousel = async () => {
    const formData = new FormData();
    formData.append("blogLanguageId", lang.id);
    formData.append("title", "Carousel");
    formData.append(`images[0]`, await urlToFile(carouselImageOne));
    formData.append(`images[1]`, await urlToFile(carouselImageTwo));
    formData.append(
      `content`,
      "Placeholder sentence 1-.-Placeholder sentence 2"
    );
    addSection(formData);
  };
  const addCarouselItem = async () => {
    const formData = new FormData();
    formData.append("blogLanguageId", lang.id);
    formData.append("index", 1);
    formData.append("_method", "PUT");
    formData.append(
      `images[${carouselItems?.length}]`,
      await urlToFile(carouselImageOne)
    );
    formData.append(
      "content",
      [
        ...carouselItems,
        { content: `Placeholder sentence ${carouselItems?.length + 1}` },
      ]
        .map((item) => item.content)
        .join("-.-")
    );
    await updateSection(formData);
  };
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        outline: "4px dashed #D51C29",
        marginBottom: "20px",
        padding: carouselItems ? "0" : "20px",
      }}
    >
      {carouselItems && (
        <>
          <input
            type="submit"
            name="add-carousel-item"
            value="Add Carousel Item"
            onClick={addCarouselItem}
            disabled={isUpdateSectionLoading}
            style={{
              marginBottom: "40px",
              position: "absolute",
              top: "20px",
              left: "20px",
              zIndex: "100",
            }}
          />
        </>
      )}
      {carouselItems ? (
        <Slider {...settings} className="home-slider">
          {carouselItems?.map((item, index) => (
            <SliderItem
              isUpdatingCarousel={isUpdateSectionLoading}
              urlToFile={urlToFile}
              index={index}
              contentEditable
              onChange={(e) => {
                let newCarouselItems = [...carouselItems];
                newCarouselItems[index].content = e.target.value;
                setCarouselItems(newCarouselItems);
              }}
              updateCarousel={updateCarousel}
              changeCarouselImage={changeCarouselImage}
              backgroundImage={item.image}
              title={item.content}
              key={index}
            />
          ))}
        </Slider>
      ) : isAddSectionLoading ? (
        <Loading color={"#0791BE"} />
      ) : (
        <input
          type="submit"
          disabled={isAddSectionLoading}
          name="add-carousel-section"
          value="Add a Carousel"
          style={{ margin: "auto", display: "block" }}
          onClick={addCarousel}
        />
      )}
    </div>
  );
};

export default CarouselSection;
